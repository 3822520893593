.header-app{
    width: 100%;
    height: 100px;
    padding-top: 3px;
    color: #fff;
    background-color: rgb(165, 35, 35);
    text-align: center;
}
.dashboard{

}
.dashboard-body {
    width: 100%;
    background: #A52323;
    height: 900px;
}
.dashboard-container {
    width: 100%;
    height: auto;
}
.booking-form-lable{
    margin: 10px;
}
.restaurant-inner-body{
    width: 90%;
    height: 92%;
    padding-top: 44px;
    margin: auto;
    background: #2252a966
}
.restaurant-list {
    background: #fff;
    margin: 46px;
    height: 166px;
    border-radius: 28px;
}
.restaurant-img {
    width: 110px;
    float: left;
    height: 110px;
}
.restaurant-img img{
    width: 98px;
    margin-top: 27px;
    margin-left: 1px;
    height: auto;
    image-rendering: pixelated;
}
.restaurant-details {
    float: right;
    width: 49%;
    height: 110px;
    padding-top: 14px;

}
.restaurant-details h1 {
    font-size: 18px;
}
.booking-body {
    width: 100%;
    height: 100%;
    padding: 30px;
    background: #c83c3c;
    color: #fff;
}
.booking-heading {
    font-size: 47px;
    text-align: center;
}
.form-body {
    width: 50%;
    margin: auto;
    height: auto;
}
.form-body li{
    list-style: none;

}
.form-body span{

}
.form-body input{
     padding: 10px;
 }
.form-body select {
    padding: 10px;
}
.spacing{
    height: 300px;
}
.container{
    width: 100%;
    height: 90%;
}
.restaurant-Search {
    width: 100%;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.restaurant-Search label{
    color: #fff;
}
.restaurant-Search input {
    padding: 8px;
    margin: 10px;
    border-radius: 7px;
    width: 170px;
}
.restaurant-Search button {
    padding: 10px;
    border-radius: 7px;
    margin: 10px;
}
select.select-booking {
    padding: 13px;
    border-radius: 7px;
    width: 138px;
}

.main-div {
    width: 100%;
    //background-color: #000;
    font-family: auto;
}

.header-top {
    width: 100%;
    height: 58px;
    color: #fff;
    border-bottom-width: thin;
    border-bottom: 1px outset #ffffff7a;
}
.header-left-div {
    width: 50%;
    text-align: end;
    padding-top: 25px;
    float: left;
}
.header-left-div span {
    margin-left: 10px;
}
.header-right-div {
    width: 50%;
    float: right;
    text-align: center;
    padding-top: 25px;
}
.header-right-div span {
    margin-left: 10px;
}
.header-top-icon{
    color: blanchedalmond;

}
.header {
    width: 100%;
    height: 85px;
    padding: 1px;
}
.header-logo {
  padding-top: 5px;
  padding-bottom: 5px;
    float: left;
    color: #fff;
}

.header-menu {
    padding-top: 10px;
    float: right;
}
.header-menu ul li {
    list-style: none;
    color: #000!important;
    float: left;
    margin-top: 16px;
    margin-left: 17px;
    font-family: auto;
    font-size: 19px;
    border: 1px solid;
    background: #fff;
    padding: 10px;
    margin-bottom: 27px;
}
.slider-div {
    width: 100%;
    padding: 1px;
    height: auto;
    background-color: #1f1a1861;
}
.slider-content {
    padding-top: 100px;
    font-size: 27px;
    height: 265px;
    line-height: 13px;
    text-align: center;
    color: #fff;
}
.slider-content p {
    font-size: 28px;
}
.slider-content h1 {
    font-size: 56px;
}
.slider-content span{
    color: rgb(229, 138, 74);
}
.footer-outer-div{
    height: 250px;
}
.footer-logo {
    padding-left: 18px!important;
    color: #fff;
    padding-top: 52px;
    padding-right: 23px!important;
}
.footer-logo li {
    list-style: none;
}
.footer-copy-right {
    background: #fff;
}
.footer-copy-right  p {
    padding-top: 15px;
}
.adminLogin{
  background: #efa670;
  height: 526px;
}
.login-page {
    margin-top: 30%;
}
.Login .btn-primary {
    background-color: #C5641B !important;
    color: #fff !important;
    border-color: #C5641B !important;
}
.Login {
    margin-top: 20px;
    padding-bottom: 30px;
    background-color: rgb(230, 216, 205);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 0px 20px #000;
}
.Login label {
    font-weight: 500;
}
ul.menu-link li a {
    color: #000;
    text-decoration-line: none;
}

.admin_header{
     background-color: #0a0a0a;
}
.admin-brand img {
  width: 120px;
    margin-left: 30px;
}
.admin_detail{
    width: 100%;
    background: #efa670;
    height: 1000px;
}
.admin_detail-link-button {
    background: none;
    cursor: pointer;
    font-weight: bold;
    color: #000;
    border: none;
    font-family: monospace;
}
.switch.on {
    background: #c5641b !important;
}
.switch {
    border: 1px solid #fdf9f9 !important;
}
.admin-form-page{
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: rgb(230, 216, 205);
    box-shadow: 0px 0px 20px #000;
}
.admin-form-page span {
    display: block;
}
.admin_body{
    width: 100%;
    background-color: #efa670;
    height: auto;
}
.admin_body .btn-primary {
    color: #fff;
    background-color: #589be0f2;
    border-color: #589be0f2;
    margin-top: 20px;
}
.admin_body h1 {
    font-family: sans-serif;
    font-size: 32px;
    font-style: normal;
}
.admin_detail label {
    font-weight: 500;
}
span.small-heading {
    font-weight: 500;
}

.restaurant-info {
    background-color: rgb(230, 216, 205);
    margin-top: 40px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 20px #000;
}
.restaurant-info h1 {
    text-align: center;
    padding-bottom: 10px;
    font-family: sans-serif;
    margin-bottom: 0px;
}
.restaurant-info label {
    font-weight: 500;
}
.restaurant-info .table td, .table th {

     border-top:none!important;
}
.restaurant-info button {
    margin-left: 225px;
}
.restaurant-info hr{
    margin-bottom: 30px !important;
    border-top: 2px solid #efa670 !important;
    width: 220px !important;
    margin-top: 0px !important;
    margin-bottom: 30px;
}
.RestaurantInfo-confrmation {
     padding: 15px;
    text-align: center;
    height: 550px;
}
.ResturantInfo-loader {
  height: 550px;
  text-align: center;
  padding-top: 160px;
}
.RestaurantInfo-confrmation-heading {
    padding-top: 160px;
}
.copy {
    background: #fff;
}
.copy-wrapper p {
    font-size: 16px;
    margin-bottom: 0;
    color: #000;
    font-weight: 500;
}
.admin-loader {
    text-align: center;
}
